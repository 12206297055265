<script setup>
import { ref, watch } from 'vue'
import { config } from '@dpgradio/creative'
import { useChartStore } from '../stores/chart'
import { useVotesStore } from '../stores/votes'
import { useUserStore } from '../stores/user'
import Layout from '../components/Layout.vue'
import Title from '../components/elements/Title.vue'
import PrimaryButton from '../components/elements/PrimaryButton.vue'
import Switch from '../components/elements/Switch.vue'
import Header from '../components/elements/Header.vue'
import TextInput from '../components/elements/TextInput.vue'
import Confirm from '../components/Confirm.vue'

const chart = useChartStore()
const votes = useVotesStore()
const user = useUserStore()

const updatePhoneNumberQuestion = ref(false)
const updatePhoneNumber = ref(null)

const updateCrmConsent = (val) => {
  if (val) {
    votes.meta.crmConsent = {
      touchpoint_url: window.location.href,
      question: user.crmConsentQuestion,
    }
  } else {
    votes.meta.crmConsent = false
  }
}

watch(
  () => votes.meta.phoneConsent,
  () => {
    if (votes.meta.phoneConsent) {
      if (user?.profile?.mobile) {
        updatePhoneNumberQuestion.value = true
      } else {
        updatePhoneNumber.value = true
      }
    } else {
      updatePhoneNumberQuestion.value = false
      updatePhoneNumber.value = null
    }
  },
  { immediate: true }
)
</script>

<template>
  <Layout>
    <Header class="space-y-6">
      <Title>Blijf op de hoogte</Title>

      <p>Wil je weten of jouw tracks de lijst hebben gehaald?</p>

      <div class="flex space-x-2 text-left">
        <Switch v-model="votes.meta.notificationConsent" class="mt-2" />
        <div class="flex-1">
          <p class="!leading-snug !mb-1">
            Stuur mij push notificaties wanneer mijn top-{{ chart.constraints.minVotes }} tracks te horen zijn.
          </p>
          <p class="!leading-none !text-xs text-gray-500">
            Push notificaties ontvang je alleen wanneer je toestemming hebt gegeven voor notificaties in de
            {{ config('name') }} app.
          </p>
        </div>
      </div>

      <div class="flex space-x-2 text-left">
        <Switch :modelValue="votes.meta.crmConsent" class="mt-2" @update:modelValue="updateCrmConsent" />
        <div class="flex-1">
          <p class="!leading-snug !mb-1">
            {{ user.crmConsentQuestion }}
          </p>
          <p class="!leading-none !text-xs text-gray-500">
            We vinden het leuk om je op de hoogte te houden over deze en andere acties!
          </p>
          <div v-if="votes.meta.crmConsent && !user?.profile?.email" class="mt-4">
            Op welk email adres mogen we jou contacteren?
            <TextInput v-model="votes.meta.crmConsent.email" label="Email" inputClass="mt-1 lg:!w-1/3" />
          </div>
        </div>
      </div>

      <div>
        <div class="flex space-x-2 text-left">
          <Switch v-model="votes.meta.phoneConsent" class="mt-2" />
          <div class="flex-1">
            <p class="!leading-snug !mb-1">{{ config('name') }} mag mij bellen in de uitzending.</p>
            <p class="!leading-none !text-xs text-gray-500">
              We spreken je graag over de nummers die jij gekozen hebt.
            </p>
            <div v-if="updatePhoneNumberQuestion" class="mt-4">
              Is je telefoonnummer nog steeds
              <strong> {{ user.profile?.mobile?.replace(/^\+\d\d\./, '0') }} </strong>?
              <div class="mt-1 space-x-2">
                <PrimaryButton type="white" :selected="updatePhoneNumber === false" @click="updatePhoneNumber = false">
                  Ja
                </PrimaryButton>
                <PrimaryButton type="white" :selected="updatePhoneNumber === true" @click="updatePhoneNumber = true">
                  Nee
                </PrimaryButton>
              </div>
            </div>
            <div v-if="updatePhoneNumber" class="mt-4">
              Op welk nummer kunnen we je bereiken?
              <TextInput v-model="user.newPhoneNumber" label="Telefoonnummer" inputClass="mt-1 lg:!w-1/3" />
            </div>
          </div>
        </div>
      </div>

      <Confirm />
    </Header>
  </Layout>
</template>
