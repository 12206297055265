<script setup>
import { defineEmits } from 'vue'
import Title from '../../elements/Title.vue'
import ChevronLeftIcon from '../../../assets/icons/chevron_left.svg'
import TrackOption from '../../tracks/TrackOption.vue'
import ArtistImage from './ArtistImage.vue'
import LoadingIndicator from '../../elements/LoadingIndicator.vue'
import { useArtistsStore } from '../../../stores/artists'
import CustomTrackModalButton from '../CustomTrackModalButton.vue'

const props = defineProps({
  artist: { type: Object, required: true },
})

const emit = defineEmits(['back'])

const artists = useArtistsStore()

artists.fetchTracksByArtist(props.artist)
</script>

<template>
  <div class="space-y-6">
    <button class="flex items-center text-base font-sans" @click="emit('back')">
      <ChevronLeftIcon class="h-6" />
      <span>Terug</span>
    </button>
    <div class="flex items-center space-x-4">
      <ArtistImage v-if="artist.image" class="h-24" :src="artist.image" />
      <Title>{{ artist.name }}</Title>
    </div>
    <LoadingIndicator v-if="!artists.tracks(artist)" />
    <template v-else>
      <div class="divide-y divide-solid divide-element-dark px-4 py-2 bg-element">
        <TrackOption v-for="track in artists.tracks(artist)" :key="track.id" :track="track" />
      </div>
      <div class="space-y-2 pb-6">
        <span>
          Staat je favoriete track van {{ artist.name }} er niet bij? Geef ons de titel door en wij voegen het toe aan
          je stemlijst!
        </span>
        <CustomTrackModalButton :artist="artist.name" />
      </div>
    </template>
  </div>
</template>
