<script setup>
import { useVotesStore } from '../../stores/votes'
import Track from '../tracks/Track.vue'
import Subtitle from '../elements/Subtitle.vue'
import TextAreaInput from '../elements/TextAreaInput.vue'
import { useChartStore } from '../../stores/chart'

const chart = useChartStore()
const votes = useVotesStore()

const questions = [
  {
    title: `Waarom moet deze track in de ${chart.name}?`,
    placeholder: 'Schrijf jouw verhaal',
  },
  {
    title: 'Wat is er speciaal aan deze keuze?',
    placeholder: 'Onderbouw jouw keuze',
  },
  {
    title: 'Waarom kies je voor deze track?',
    placeholder: 'Typ jouw motivatie',
  },
  {
    title: 'Waar denk je aan bij deze track?',
    placeholder: 'Schrijf jouw verhaal',
  },
  {
    title: 'Wat is jouw verhaal bij deze track?',
    placeholder: 'Deel jouw verhaal',
  },
  {
    title: 'Waarom is deze track speciaal voor jou?',
    placeholder: 'Onderbouw jouw keuze',
  },
  {
    title: 'Waarom heb je deze track geselecteerd?',
    placeholder: 'Schrijf jouw verhaal',
  },
  {
    title: 'Vertel ons jouw herinnering bij deze track',
    placeholder: 'Deel jouw motivatie',
  },
]

const randomQuestion = () => questions[Math.floor(Math.random() * questions.length)]

const tracksAndQuestions = votes.tracks.map((track) => ({
  track,
  question: randomQuestion(),
}))

const categories = [
  'Anders, namelijk...',
  'Liefde',
  'Lijflied',
  'Huwelijk',
  'Goed gevoel',
  'Vakantie',
  'Schooltijd',
  'Geboorte',
  'Jeugdsentiment',
  'Dierbare herinnering',
  'Fan',
  'Liefdesverdriet',
  'Eerste keer',
  'Mijn nummer 1',
  'Meezingers',
  'Familie',
]

const formatPlaceholder = ({ placeholder }) => (chart.hasCommentCategories ? `${placeholder} (optioneel)` : placeholder)
</script>

<template>
  <div class="space-y-8">
    <div v-for="{ track, question } in tracksAndQuestions" :key="track.id" class="space-y-2">
      <Track :track="track" />
      <Subtitle class="leading-none">
        {{ question.title }}
        <span class="text-xs text-gray-500">Optioneel</span>
      </Subtitle>
      <div v-if="chart.hasCommentCategories">
        <select
          v-model="votes.commentCategories[track.id]"
          class="!px-4 !py-2 !bg-element-light !rounded-lg !w-full lg:!w-1/3"
        >
          <option :value="undefined" disabled selected>Kies jouw onderwerp</option>
          <option v-for="category in categories" :key="category" :value="category" v-text="category" />
        </select>
      </div>
      <TextAreaInput
        v-if="!chart.hasCommentCategories || votes.commentCategories[track.id]"
        v-model="votes.comments[track.id]"
        :placeholder="formatPlaceholder(question)"
      />
    </div>
  </div>
</template>
