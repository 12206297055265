import { cdnImageUrl, config } from '@dpgradio/creative'

export const mapRawTracks = (rawTracks) =>
  rawTracks.map((track) => ({
    id: track.id,
    title: track.title,
    artist: track.artist.name,
    artistId: track.artist.id,
    thumbnail: cdnImageUrl(track.thumbnail || config('app.fallback_image')),
    preview: track.hooks?.m4a,
  }))

export const mapRawArtists = (rawArtists) =>
  rawArtists.map((artist) => ({
    id: artist.id,
    name: artist.name,
    image: artist.images?.default && cdnImageUrl(artist.images.default),
  }))
